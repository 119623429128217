/* fira-sans-condensed-300 - latin */
@font-face {
    font-family: 'Fira Sans Condensed';
    font-style: normal;
    font-weight: 300;
    src: local(''),
         url('../assets/fonts/fira-sans-condensed-v10-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../assets/fonts/fira-sans-condensed-v10-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: swap;
}
/* fira-sans-condensed-regular - latin */
@font-face {
    font-family: 'Fira Sans Condensed';
    font-style: normal;
    font-weight: 400;
    src: local(''),
         url('../assets/fonts/fira-sans-condensed-v10-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../assets/fonts/fira-sans-condensed-v10-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: swap;
}
/* fira-sans-condensed-700 - latin */
@font-face {
    font-family: 'Fira Sans Condensed';
    font-style: normal;
    font-weight: 700;
    src: local(''),
         url('../assets/fonts/fira-sans-condensed-v10-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../assets/fonts/fira-sans-condensed-v10-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: swap;
}
/* fira-sans-condensed-800 - latin */
@font-face {
    font-family: 'Fira Sans Condensed';
    font-style: normal;
    font-weight: 800;
    src: local(''),
         url('../assets/fonts/fira-sans-condensed-v10-latin-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../assets/fonts/fira-sans-condensed-v10-latin-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: swap;
}
/* fira-sans-condensed-900 - latin */
@font-face {
    font-family: 'Fira Sans Condensed';
    font-style: normal;
    font-weight: 900;
    src: local(''),
         url('../assets/fonts/fira-sans-condensed-v10-latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../assets/fonts/fira-sans-condensed-v10-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: swap;
}