.no-touch .layer:hover path,
.no-touch .layer:hover text,
.no-touch .layer:hover rect,
.no-touch .layer:hover polygon
{
    fill: #5b1eff;
}

.layer
{
    cursor: pointer;
}

.layer .shadow,
.layer .highlight {
    pointer-events: none;
}

.kicks-canvas-wrapper {
    margin: 0 auto;
    max-width: 700px;
    height: 61vw;
    overflow: hidden;
}

@media screen and (min-width: 700px) {
    .kicks-canvas-wrapper {
        max-width: 700px;
        height: 420px;
    }
}

.kicks-style-navigation .react-colorful {
    width: auto;
}

.kicks-style-navigation .react-colorful__hue {
    height: 24px;
    border-radius: 0;
}

.kicks-svg {
    background-repeat: repeat;
}

@keyframes pulseBG {
    0% {
        background-color: #222;
    }
    50% {
        background-color: #111;
    }
    100% {
        background-color: #222;
    }
}

.sample-select-item-image {
    animation: pulseBG 2s linear infinite;
}
